<template>
  <v-sheet class="pa-5" elevation="1">
    <v-sheet
      v-if="loading"
      class="d-flex align-center justify-center"
      style="height: 50vh"
    >
      <v-progress-circular
        indeterminate
        size="50"
        color="primary"
      ></v-progress-circular>
    </v-sheet>
    <div v-else>
      <profile :van="van" :updateVan="updateVan" />
      <macro-planning :van="van" class="mt-5" />
      <upcoming-trip v-if="checkUpcomingTrip" class="mt-5" :upcomingTrip="upcomingTrip" />
      <v-card class="mt-5" v-else>
        <v-card-title>No upcoming trips</v-card-title>
      </v-card>
      <v-card class="mt-5" elevation="1">
        <v-card-title>Trips</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <order-trip-table :orders="orders" />
        </v-card-text>
      </v-card>
    </div>
  </v-sheet>
</template>

<script>

import { isEmpty } from 'lodash'

export default {

  components: {
    Profile: () => import('../../components/van/Profile.vue'),
    UpcomingTrip: () => import('../../components/shared/UpcomingTrip.vue'),
    OrderTripTable: () => import('../../components/shared/OrderTripTable.vue'),
    MacroPlanning: () => import('../../components/van/MacroPlanning.vue')
  },

  data: () => ({
    loading: true,
    checkUpcomingTrip: false,
    upcomingTrip: {},
    van: {},
    orders: []
  }),

  created() {
    setTimeout(async () => {
      this.upcomingTrip = await this.$store.dispatch('van/getUpcomingTrip', { id: this.$route.params.id })
      if(!isEmpty(this.upcomingTrip)) {
        this.checkUpcomingTrip = true
      }
      this.van = await this.$store.dispatch('van/getVanById', { id: this.$route.params.id})
      this.orders = await this.$store.dispatch('order/getFullOrderDataByVanId', { vanId: this.$route.params.id})
      console.log(this.van)
      this.loading = false
    }, 0)
  },

  methods: {
    updateVan(payload) {
      this.van = payload
      console.log(this.van)
    },
  }

}
</script>

<style>

</style>